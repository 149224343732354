<template>
  <div v-frag>
    <v-row class='d-flex flex-row align-center justify-center px-10 mt-6 mb-12'>
      <v-alert
        v-model='dayOffAlert'
        class='rounded-xl'
        color='success'
        dark
        dense
        dismissible
        icon='mdi-information-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>Time Off Requested Successfully</h3>
        <p>
          You have successfully submitted your request, you can check the "Days
          Off Requests History" table to overview the status of all your
          request.
        </p>
      </v-alert>
      <v-dialog v-model='showDayOffDialog' max-width='500px'>
        <v-card class='d-flex flex-column align-start pa-2'>
          <v-card-title class='text-center mb-4'>
            <v-icon class='mr-4 white--text' color='success' large left>
              mdi-alert
            </v-icon>
            Schedule My Time
          </v-card-title>
          <v-card-subtitle>
            <b>Select a time during this election campaign:</b>
          </v-card-subtitle>
          <v-radio-group v-model='selectedDate' class='ml-6'>
            <v-radio
              v-for='date in requestItem.availableDates'
              :key='date'
              :label='date'
              :value='date'
              class='pb-2'
            >
              {{ date }}
            </v-radio>
          </v-radio-group>
          <v-card-actions class='align-self-center'>
            <v-spacer></v-spacer>
            <v-btn
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              outlined
              @click='closeRequestModal'
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled='!selectedDate'
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              @click='applyForVoting(requestItem)'
            >
              Submit
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model='showInsufficientHoursDialog' max-width='600px'>
        <v-card class='d-flex flex-column align-start pa-2'>
          <v-card-title class='text-center mb-5'>
            <v-icon class='mr-4 white--text' color='error' large left>
              mdi-alert
            </v-icon>
            Insufficient Hours
          </v-card-title>
          <v-card-subtitle>
            <b>
              You don't have enough hours to apply, please contact your
              superior in charge to require participation.
            </b>
          </v-card-subtitle>
          <v-card-actions class='align-self-center mt-n2'>
            <v-spacer></v-spacer>
            <v-btn
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              outlined
              @click='closeRequestModal'
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model='showDeniedReasonDialog' max-width='600px'>
        <v-card class='py-2 px-6'>
          <v-card-title class='text-left mb-4 ml-n6'>
            <v-icon class='error--text mr-4' large>mdi-alert</v-icon>
            Time Off Request Rejected
          </v-card-title>
          <v-card-subtitle class='text-left ml-n6 font-weight-bold'>
            Message
          </v-card-subtitle>
          <v-row>
            <v-textarea
              v-model='deniedReason'
              outlined
              readonly
              width='100%'
            ></v-textarea>
          </v-row>
        </v-card>
      </v-dialog>
      <v-dialog v-model='showOpportunityDialog' max-width='700px'>
        <v-card class='d-flex flex-column align-start pa-6'>
          <v-card-title class='text-center mb-5'>
            <v-icon class='mr-4 white--text' color='success' large left>
              mdi-alert
            </v-icon>
            Volunteering Time Off Request
          </v-card-title>
          <v-card-subtitle>
            <b>Are you sure you want to apply to this volunteering opportunity?</b>
            <v-col>
              <p class='font-weight-bold'>
                Current Available Hours:
                <span class='primary--text'>
                  {{ profile.daysOff.availableHours }}
                </span>
              </p>
            </v-col>
            <v-col class='mt-n6'>
              <p class='font-weight-bold'>
                This volunteering application will be deduced from your total
                available hours.
              </p>
            </v-col>
            <v-col v-if='requestItem.backgroundCheck' class='mt-n6'>
              <p class='font-weight-bold warning--text'>
                IMPORTANT: This volunteering requires a background check from
                part of the charity. You personal information will be provided
                for evaluation. You must agree to continue:
              </p>
              <v-checkbox
                v-model='checkbox'
                label='I agree and wish to continue with the application'
              ></v-checkbox>
            </v-col>
          </v-card-subtitle>
          <v-card-actions class='align-self-center mt-n6'>
            <v-spacer></v-spacer>
            <v-btn
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              outlined
              @click='closeRequestModal'
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled='!checkbox && requestItem.backgroundCheck'
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              @click='applyForVolunteering'
            >
              Confirm
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model='showDeleteRequestDialog' max-width='500px'>
        <v-card class='d-flex flex-column align-start pa-2'>
          <v-card-title class='text-center mb-5'>
            <v-icon class='mr-4 white--text' color='error' large left>
              mdi-alert
            </v-icon>
            Cancel Request
          </v-card-title>
          <v-card-subtitle>
            <b>Are you sure you want to cancel this request?</b>
          </v-card-subtitle>
          <v-card-actions class='align-self-center'>
            <v-spacer></v-spacer>
            <v-btn
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              outlined
              @click='closeRequestModal'
            >
              Cancel
            </v-btn>
            <v-btn
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              @click='deleteRequest'
            >
              Confirm
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card class='d-flex flex-column' rounded='xl' width='100%'>
        <v-card-title class='text-align-center align-self-center'>
          <span class='headline font-weight-bold'>Volunteer/Vote Requests</span>
        </v-card-title>
        <v-data-table
          :headers='requestsHistoryHeaders'
          :items='requests'
          :loading='loadingRequests'
          class='mx-6'
        >
          <template #item.approvedBy={item}>
            <span v-if="item.state === 'Pending'">Pending Acknowledgement</span>
            <span v-if="item.state !== 'Pending'">{{ item.approvedBy }}</span>
          </template>
          <template #item.state={item}>
            <span
              v-if="item.state === 'Rejected'"
              class='font-weight-bold error--text showPointer'
              @click='seeReason(item)'
            >
              Rejected
              <v-icon
                v-show="item.deniedReason !== ''"
                class='mr-4 white--text mb-1'
                color='error'
                small
              >
                mdi-help-circle-outline
              </v-icon>
            </span>
            <span
              v-if="item.state === 'Acknowledged'"
              class='font-weight-bold success--text text-decoration-underline'
            >
              Acknowledged
            </span>
            <span
              v-if="item.state === 'Pending'"
              class='font-weight-bold warning--text text-decoration-underline'
            >
              Pending
            </span>
            <span
              v-if="item.state === 'Rejected By Charity'"
              class='font-weight-bold error--text text-decoration-underline'
            >
              Rejected By Charity
            </span>
          </template>
          <template #item.type={item}>
            <span
              class='showPointer text-decoration-underline'
              @click='openRequestInfoModal(item)'
            >
              {{ item.type }}
            </span>
          </template>
          <template #item.actions={item}>
            <v-btn color='error' small @click='openDeleteRequestDialog(item)'>
              Cancel
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
    <v-row
      v-if="!inelegible && isModuleActive('voting')"
      class='d-flex flex-row align-center justify-center px-10 mt-6 mb-12'
    >
      <v-card
        class='d-flex flex-column'
        rounded='xl'
        width='100%'
      >
        <v-card-title class='text-align-center align-self-center'>
          <span class='headline font-weight-bold'>
            Key Dates For Current Election Cycle
          </span>
        </v-card-title>
        <v-data-table
          :headers='keyDatesHeaders'
          :items='elections'
          :loading='loadingElections'
          class='mx-6'
          hide-default-footer
        >
          <template #item.actions={item}>
            <v-btn
              :disabled='item.requested'
              class='px-6 ma-4 font-weight-bold white--text text-capitalize'
              color='primary'
              small
              @click='openDatePickerDialog(item)'
            >
              <span v-show='!item.requested'> Schedule Time</span>
              <span v-show='item.requested' class='px-3'> Scheduled </span>
            </v-btn>
          </template>
          <template #item.keyDates={item}>
            <v-btn
              class='primary--text text-capitalize'
              outlined
              small
              @click='openKeyDatesModal(item)'
            >
              See More
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
    <v-row
      v-if="isModuleActive('volunteering')"
      class='d-flex flex-row align-center justify-center px-10 mt-6 mb-12'>
      <v-card class='d-flex flex-column' rounded='xl' width='100%'>
        <v-data-table
          :footer-props='{
            disablePagination: loadingOpportunity,
            disableItemsPerPage: loadingOpportunity
          }'
          :headers='opportunitiesHeaders'
          :items='opportunities'
          :loading='loadingOpportunity'
          :options.sync='options'
          :server-items-length='totalOpportunities'
          class='mx-6 mt-6'
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title class='font-weight-bold headline'>
                Open Volunteer Opportunities
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model='search'
                class='mr-4'
                dense
                hide-details
                label='Search'
                outlined
                prepend-inner-icon='mdi-magnify'
                single-line
              >
              </v-text-field>
            </v-toolbar>
          </template>
          <template #item.spots={item}>
            <div :class="item.color + '--text'">
              {{ item.availableSpots }} / {{ item.volunteersRequired }}
            </div>
          </template>
          <template #item.charity={item}>
            <span
              class='text-decoration-underline showPointer'
              @click='goToCharityProfile(item)'
            >
              {{ item.charity.name }}
            </span>
          </template>
          <template #item.need={item}>
            {{ item.description.need }}
          </template>
          <template #item.actions={item}>
            <v-row class='d-flex flex-row align-center justify-start'>
              <v-btn
                :disabled='item.requested'
                class='px-2 ma-4 font-weight-bold white--text text-capitalize'
                color='primary'
                small
                @click='openOpportunityDialog(item)'
              >
                <span v-show='!item.requested' class='px-2'> Apply </span>
                <span v-show='item.requested' class='mr-1'> Applied </span>
              </v-btn>
              <v-btn
                class='outlined font-weight-bold white--text text-capitalize'
                color='success'
                small
                @click='openRequestInfoModal(item)'
              >
                + Info
              </v-btn>
            </v-row>
          </template>
        </v-data-table>
        <RequestInfoModal
          :close='closeRequestModal'
          :item='item'
          :open='showDialog'
        ></RequestInfoModal>
      </v-card>
    </v-row>
    <v-dialog v-model='dialogKeyDates' max-width='500px'>
      <v-card class='pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon class='mr-4 white--text' color='primary' large left>
            mdi-home-outline
          </v-icon>
          Election Key Dates Information
        </v-card-title>
        <v-simple-table
          v-show='electionKeyDates.length'
          class='px-4 mx-0'
          width='100%'
        >
          <template #default>
            <thead>
            <tr>
              <th class='text-left'>Date</th>
              <th class='text-left'>Time</th>
              <th class='text-left'>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='date in electionKeyDates' :key='date.id'>
              <td>{{ date.date }}</td>
              <td>{{ date.time }}</td>
              <td>{{ date.description }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <p
          v-show='!electionKeyDates.length'
          class='secondary--text px-4 py-10 text-center'
        >
          There is no available information about this campaign yet.
        </p>
        <v-card-actions class='d-flex flex-row justify-center mt-4'>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize'
            color='primary'
            large
            outlined
            @click='dialogKeyDates = false'
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import employee from '../../services/employee'
import employeeProfile from '../../services/employeeProfile'
import RequestInfoModal from './components/requestInfoModal.vue'
import {mapGetters} from 'vuex'

export default {
  components: {RequestInfoModal},
  data: () => ({
    totalOpportunities: 0,
    options: {},
    search: '',
    searchTimer: null,
    checkbox: false,
    alreadyRequested: false,
    selectedDate: '',
    inelegible: false,
    date: '',
    item: [],
    loadingRequests: true,
    loadingOpportunity: false,
    loadingElections: true,
    showDialog: false,
    showDayOffDialog: false,
    showDeleteRequestDialog: false,
    showOpportunityDialog: false,
    showInsufficientHoursDialog: false,
    showDeniedReasonDialog: false,
    dialogKeyDates: false,
    deniedReason: '',
    dayOffAlert: false,
    employeeId: Number,
    requests: [],
    elections: [],
    opportunities: [],
    electionKeyDates: [],
    requestsHistoryHeaders: [
      {
        text: 'Acknowledged By',
        align: 'start',
        sortable: true,
        value: 'approvedBy',
        width: '20%'
      },
      {text: 'Date', value: 'date'},
      {text: 'Hours', value: 'hours'},
      // { text: "Leave", value: "leave" },
      {text: 'Type', value: 'type'},
      {text: 'Status', value: 'state'},
      {text: 'Actions', value: 'actions'}
    ],
    keyDatesHeaders: [
      {text: 'Start Date', value: 'start', width: '15%'},
      {text: 'End Date', value: 'end', width: '15%'},
      {text: 'Topic', value: 'description'},
      {text: 'Election Key Dates', value: 'keyDates', width: '15%'},
      {text: 'Actions', value: 'actions', width: '10%'}
    ],
    opportunitiesHeaders: [
      {
        text: 'Charity',
        align: 'start',
        sortable: true,
        value: 'charity'
      },
      {
        text: 'Need',
        align: 'start',
        sortable: true,
        value: 'need'
      },
      {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'date'
      },
      {
        text: 'Hs.',
        align: 'start',
        sortable: true,
        value: 'hours'
      },
      {
        text: 'Time',
        align: 'start',
        sortable: true,
        value: 'time'
      },
      {
        text: 'Address',
        align: 'start',
        sortable: true,
        value: 'address'
      },
      {
        text: 'Spots',
        align: false,
        sortable: false,
        value: 'spots'
      },
      {
        text: 'Actions',
        align: false,
        sortable: false,
        value: 'actions',
        width: '20%'
      }
    ],
    requestItem: {},
    profile: {
      daysOff: {
        availablePaidHours: 0,
        availableUnpaidHours: 0
      }
    }
  }),
  watch: {
    options: {
      async handler() {
        await this.getOpportunitiesByEmployee()
      },
      deep: true
    },
    search() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async() => await this.getOpportunitiesByEmployee(), 1000)
    }
  },
  async created() {
    await this.setEmployeeId()
    await this.initialize()
  },
  computed: {
    ...mapGetters(['getModules', 'getEmployeeId'])
  },
  methods: {
    async initialize() {
      await this.$store.dispatch('addModules')
      await this.getEmployeeProfile()
      await this.getRequestHistory()
      await this.getElections()
    },
    isModuleActive(module) {
      return this.getModules.includes(module)
    },
    async getEmployeeProfile() {
      try {
        this.profile = await employeeProfile.getEmployeeProfile(this.getEmployeeId)
        if (this.profile.inelegible) this.inelegible = true
      } catch (err) {
        console.log(err)
      }
    },
    closeRequestModal() {
      this.showOpportunityDialog = false
      this.showDayOffDialog = false
      this.showInsufficientHoursDialog = false
      this.showDialog = false
      this.showDeleteRequestDialog = false
      this.requestItem = {}
      this.selectedDate = ''
    },

    async setEmployeeId() {
      const userId = this.$store.getters.user.id
      try {
        const employee = await employeeProfile.getEmployeeId({userId: userId})
        this.$store.commit('SET_EMPLOYEE_ID', employee.employeeId)
      } catch (err) {
        console.log(err)
      }
    },
    async getRequestHistory() {
      this.requests = []
      try {
        const requests = await employee.getRequestHistory({
          id: this.getEmployeeId
        })
        const voteRequests = requests.voteRequests
        const charityRequests = requests.charityRequests
        const registrationRequests = requests.registrationRequests
        this.requests.push(...voteRequests, ...charityRequests, ...registrationRequests)
        this.requests = this.requests.sort((a, b) => new Date(b.date) - new Date(a.date))
        this.loadingRequests = false
      } catch (err) {
        console.log(err)
      }
    },
    async getOpportunitiesByEmployee() {
      this.loadingOpportunity = true
      const {sortBy, sortDesc, page, itemsPerPage} = this.options
      const filters = {
        sortBy: sortBy['0'],
        sortDesc: sortDesc['0'],
        page,
        itemsPerPage,
        search: this.search
      }
      try {
        const res = await employee.getOpportunitiesByEmployee(this.getEmployeeId, filters)
        this.totalOpportunities = res.total
        this.opportunities = res.data
      } catch (err) {
        this.loadingOpportunity = false
        console.log(err)
      }
      this.loadingOpportunity = false
    },
    async getElections() {
      try {
        this.elections = await employeeProfile.getElections(this.getEmployeeId)
      } catch (err) {
        console.log(err)
      }
      this.loadingElections = false
    },
    openDatePickerDialog(item) {
      const array = this.getDateArray(
        item.start,
        item.end,
        item.earlyVoteStartDate,
        item.earlyVoteEndDate,
        item.voteDate
      )
      item = {...item, availableDates: array}

      this.requestItem = item
      this.showDayOffDialog = true
    },
    openOpportunityDialog(item) {
      this.requestItem = item
      const availableHours = this.profile.daysOff.availableHours
      // const availableHours = 100;
      if (this.requestItem.hours > availableHours)
        this.showInsufficientHoursDialog = true
      else this.showOpportunityDialog = true
    },
    openDeleteRequestDialog(item) {
      this.requestItem = item
      this.showDeleteRequestDialog = true
    },
    async submitVolunteeringRequest() {
      const eventArray = []
      eventArray.push(this.requestItem.id)
      try {
        await employee.submitVolunteerRequest({
          employeeId: this.getEmployeeId,
          eventDayId: eventArray
        })
        this.requests = []
        await this.getRequestHistory()
        await this.getOpportunitiesByEmployee()
        await this.getEmployeeProfile()
      } catch (err) {
        console.log(err)
      }
    },
    async deleteRequest() {
      const requestId = this.requestItem.id
      const requestType = this.requestItem.type
      const requestHours = this.requestItem.hours
      const index = this.requests.indexOf(this.requestItem)
      try {
        await employee.deleteRequest({
          id: this.getEmployeeId,
          requestId: requestId,
          requestType: requestType
        })
        this.requests.splice(index, 1)
        this.profile.daysOff.availableHours =
          this.profile.daysOff.availableHours + requestHours
        this.closeRequestModal()
        await this.getElections()
        await this.getOpportunitiesByEmployee()
      } catch (err) {
        console.log(err)
      }
    },
    async submitVotingRequest() {
      try {
        const response = await employee.submitVotingRequest({
          electionId: this.requestItem.id,
          voteDate: this.selectedDate,
          employeeId: this.getEmployeeId
        })
        await this.getElections()
        this.requests = []
        await this.getRequestHistory()
        return response
      } catch (err) {
        console.log(err)
      }
    },
    async applyForVoting() {
      const response = await this.submitVotingRequest()
      this.closeRequestModal()
      if (response.data.success) {
        this.showAlertMsg()
      }
    },
    async applyForVolunteering() {
      await this.submitVolunteeringRequest()
      this.closeRequestModal()
      this.showAlertMsg()
    },
    showAlertMsg() {
      this.dayOffAlert = true
      this.hideAlertMsg()
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.dayOffAlert = false
      }, 6000)
    },
    getDateArray(start, end, earlyStart, earlyEnd, voteDate) {
      // merge dates from early voting and vote day
      // or if all empty - from start of election to it end
      let dateArray = []
      if (earlyStart && earlyEnd) {
        let dates = this.getDaysArray(earlyStart, earlyEnd)
          .map(el => el.toISOString()
            .slice(0, 10))
        dateArray = [...dates]
      }
      if (voteDate) dateArray.push(new Date(voteDate).toISOString()
        .slice(0, 10))
      if (!dateArray) {
        const dates = this.getDaysArray(start, end)
          .map(el => el.toISOString()
            .slice(0, 10))
        dateArray = [...dates]
      }
      return Array.from(new Set(dateArray.filter(el => new Date(new Date(el).setMilliseconds(6 * 3600 * 1000)) >= Date.now())
        .sort()))
    },
    getDaysArray(start, end) {
      start = new Date(start)
      end = new Date(end)
      let arr = []
      for (let dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt))
      }
      return arr
    },
    goToCharityProfile(item) {
      this.$store.dispatch('charity', item.charity.id)

      this.$router.push('/employee/charity-donations')
    },
    openRequestInfoModal(item) {
      this.item = []
      this.item.push(item)
      this.showDialog = true
    },
    openKeyDatesModal(item) {
      this.electionKeyDates = []
      this.electionKeyDates = item.keyDates
      this.dialogKeyDates = true
    },
    seeReason(item) {
      this.deniedReason = item.deniedReason

      if (this.deniedReason !== '') {
        this.showDeniedReasonDialog = true
      }
    }
  }
}
</script>
<style>
.showPointer:hover {
  cursor: pointer;
}
</style>
