<template>
  <v-dialog v-model='open' max-width='1500px' persistent scrollable>
    <v-card class='pa-6' height='100%'>
      <v-data-table
        :headers='headers'
        :items='item'
        class='mt-6 elevation-4 my-table'
        hide
        hide-default-footer
      >
      </v-data-table>
      <v-data-table
        :headers='description'
        :items='item'
        class='mt-6 elevation-4 my-table'
        hide-default-footer
      ></v-data-table>

      <v-data-table
        v-show='charity'
        :headers='contactInfo'
        :items='item'
        class='mt-6 elevation-4 my-table'
        hide-default-footer
      ></v-data-table>
      <v-card-actions class='d-flex direction-row justify-center mt-6'>
        <v-btn
          class='px-14 ma-4 font-weight-bold'
          color='primary'
          large
          outlined
          @click='closeDialog'
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['open', 'item'],
  updated() {
    if (this.open) {
      if (this.item[0].charity) {
        this.charity = true
        this.headers = this.volunteeringHeaders
        this.description = this.volunteeringDescription
      }
      if (this.item[0].election) {
        this.charity = false
        this.headers = this.votingHeaders
        this.description = this.votingDescription
      }
    }
  },
  data: () => ({
    headers: [],
    description: [],
    charity: false,
    volunteeringHeaders: [
      {
        text: 'Charity',
        align: 'start',
        sortable: true,
        value: 'charity.name',
        divider: true
      },
      {
        text: 'Need',
        align: 'start',
        sortable: true,
        value: 'charityEvent.need',
        divider: true
      },
      {
        text: 'Hours',
        align: 'start',
        sortable: true,
        value: 'charityEvent.hoursRequired',
        divider: true
      },
      {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'date',
        divider: true
      },
      {
        text: 'Address',
        align: 'start',
        sortable: true,
        value: 'charityEvent.address',
        divider: true
      },
      {
        text: 'Start',
        align: 'start',
        sortable: true,
        value: 'charityEvent.startHour',
        divider: true
      },
      {
        text: 'End',
        align: 'start',
        sortable: true,
        value: 'charityEvent.endHour',
        divider: true
      }
    ],
    volunteeringDescription: [
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'charityEvent.description'
      }
    ],
    contactInfo: [
      {
        text: 'Charity Email',
        align: 'start',
        sortable: false,
        value: 'charity.generalEmail'
      },
      {
        text: 'Charity Phone',
        align: 'start',
        sortable: false,
        value: 'charity.generalPhone'
      },
      {
        text: 'Contact Name',
        align: 'start',
        sortable: false,
        value: 'charity.contactName'
      },
      {
        text: 'Contact Phone',
        align: 'start',
        sortable: false,
        value: 'charity.contactPhone'
      }
    ],
    votingHeaders: [
      {
        text: 'Campaign',
        align: false,
        sortable: false,
        value: 'election.description',
        divider: true
      },
      {
        text: 'Date',
        align: false,
        sortable: false,
        value: 'date',
        divider: true
      },
      {
        text: 'Leave',
        align: false,
        sortable: false,
        value: 'leave',
        divider: true
      }
    ]
  }),
  methods: {
    closeDialog() {
      this.$emit('close')
    }
  }
}
</script>
